.ul{
  position: absolute;
  height: 700px;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  overflow: scroll;
  margin: 0;
  padding: 0;
  
  border: 2px solid #ccc;
  
  font-size: 16px;
  font-family: Arial, sans-serif;
  
  -webkit-overflow-scrolling: touch;
}
.li{
  width: 100%;
  word-wrap: break-word;
  height: auto;
  min-height: inherit;
}
.SelectSize{
    width: 265px;
}